import PercentIcon from "./src/percentage.png"
import CompanyLogo from "./src/main-logo.png"

import Road from "./src/road.png"
import User from "./src/user.png"
import Eye from "./src/eye.png"
import EyeHidden from "./src/eye-hidden.png"
import EyeWhite from "./src/eye-white.png"
import EyeHiddenWhite from "./src/eye-hidden-white.png"

import PurpleMarker from "./src/purple-marker.png"
import GreenMarker from "./src/green-marker.png"
import RedMarker from "./src/red-marker.png"
import OrangeMarker from "./src/orange-marker.png"
import BlackMarker from "./src/black-marker.png"
import PurpleMarkerr from "./src/purple-markerr.png"
import BrownMarker from "./src/brown-marker.png"

import Pin from "./src/pin.png"
import Pos from "./src/pos.png"
import Productivity from "./src/productivity.png"

const Icons = {
  CompanyLogo,
  BlackMarker,
  PercentIcon,
  User,
  Eye,
  EyeHidden,
  EyeWhite,
  EyeHiddenWhite,
  PurpleMarker,
  GreenMarker,
  RedMarker,
  OrangeMarker,
  PurpleMarkerr,
  BrownMarker,
  Pin,
  Pos,
  Productivity,
  Road,
}

export default Icons
