import { toast } from "react-toastify"
import AppConfig from "../../config"

const useNetworkRequests = () => {
  const sendMessageToDownloadExcel = async () => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(`/areas/report`, {})
      toast.success(
        "Rapor indirme işlemi devam ediyor, kısa süre içinde indirme mesajı alacaksınız. Lütfen bekleyin."
      )
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error(
          "İndirme bekleme sırasında bir hata oluştu. Sorunun giderilmesi için çalışıyoruz, lütfen daha sonra tekrar deneyin."
        )
      }
    }
  }

  const intiateOptimization = async () => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(`/areas/optimize`, {})
      toast.success(
        "İşlem alındı. Tamamlanana kadar bekleyebilir veya sayfayı kapatabilirsiniz. Biz çalışmaya devam edeceğiz."
      )
    } catch (e) {
      toast.error(
        "İşlem tamamlanamadı. Biraz bekleyip tekrar deneyebilirsiniz, başka bir işlem çalışıyor olabilir."
      )
    }
  }

  const requestRouteOptimization = async (area_id) => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(
        `/areas/${area_id}/optimize`,
        {}
      )
      toast.success("Optimize ediliyor, lütfen bekleyin")
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else if (e.response?.status === 423) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error("Optimizede hata oluştu")
      }
    }
  }
  const requestDuplicateArea = async (area_id) => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(
        `/areas/${area_id}/duplicate`,
        {}
      )
      toast.success("Kopyalaniyor, lütfen bekleyin")
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error("Kopyada hata oluştu")
      }
    }
  }

  const requestOptimizationDaySwap = async (area_id) => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(
        `/areas/${area_id}/optimize/withinrouteswap`,
        {}
      )
      toast.success("Optimize ediliyor, lütfen bekleyin")
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error("Optimizede hata oluştu")
      }
    }
  }

  const requestRouteReport = async (area_id) => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(
        `/areas/${area_id}/report`,
        {}
      )
      toast.success(
        "Rapor indirme beklemede, birazdan indirmeniz için mesaj gelecek, lütfen bekleyin."
      )
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error("Raporu indirme beklemede hata oluştu")
      }
    }
  }

  const resetBack = async () => {
    try {
      await AppConfig.axiosInstances.MainAuth.post(`/areas/reset`, {})
      // toast.success("Geri alınıyor, lütfen bekleyin");
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error("Geri almada hata oluştu")
      }
    }
  }

  const deleteArea = async (id) => {
    try {
      await AppConfig.axiosInstances.MainAuth.delete(`/areas/${id}`)
      toast.success("Bölge silindi")
    } catch (e) {
      if (e.response?.status === 503) {
        toast.error(
          "Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin."
        )
      } else {
        toast.error("Bölge silmede hata oluştu")
      }
    }
  }

  return {
    sendMessageToDownloadExcel,
    intiateOptimization,
    requestRouteOptimization,
    requestOptimizationDaySwap,
    requestRouteReport,
    requestDuplicateArea,
    resetBack,
    deleteArea,
  }
}

export default useNetworkRequests
