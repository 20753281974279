import _ from "lodash"

const timeToReadable = (item) => {
  const timeString = String(_.clone(item))
  if (!timeString) return "0'0''"
  const hours = Number(timeString.split(".")[0])
  const minutes = Math.round(Number(item % 1) * 60)
  return `${hours}'${minutes}''`
}

const percentToReadable = (item) => {
  const percent = item * 100
  return "%" + percent.toFixed(0)
}

const interpolate = (x) => {
  switch (x) {
    case -1:
      return "rgb(125,125,125)" //0
    case 1:
      return "rgba(255,0,0,0.5)"
    case 2:
      return "rgb(0,255,255)" //1
    case 3:
      return "rgb(0,0,255)" //2
    case 4:
      return "rgb(255, 165, 0)" //3
    case 5:
      return "rgb(255,0,255)" //4
    case 6:
      return "rgb(27, 93, 52)" //5
    case 7:
      return "rgb(125, 0,0)" //6
    case 8:
      return "rgb(0, 125,0)"
    case 9:
      return "rgb(0, 125,125)"
    case 10:
      return "rgb(125, 0,125)"
    case 11:
      return "rgb(35, 0,100)"
    case 12:
      return "rgb(0, 35, 100)"
    case 13:
      return "rgb(50, 50,50)"
    case 14:
      return "rgb(255, 30,125)"
    case 15:
      return "rgb(125, 255,30)"
    case 16:
      return "rgb(45, 90,125)"
    case 17:
      return "rgba(80, 15,115, 0.5)"
    case 18:
      return "rgb(180, 215,55)"
    case 19:
      return "rgb(180, 25,5)"
    case 20:
      return "rgb(10, 15,55)"
    default:
      return "rgb(17, 15,55)"
  }
}

export const getIndexByDay = {
  Pazartesi: 0,
  Salı: 1,
  Çarşamba: 2,
  Perşembe: 3,
  Cuma: 4,
  Cumartesi: 5,
  Pazar: 6,
}

const pointIsInPolygon = (p, polygon) => {
  let minX = polygon[0].lat
  let maxX = polygon[0].lat
  let minY = polygon[0].lng
  let maxY = polygon[0].lng
  for (let i = 1; i < polygon.length; i++) {
    const q = polygon[i]
    minX = Math.min(q.lat, minX)
    maxX = Math.max(q.lat, maxX)
    minY = Math.min(q.lng, minY)
    maxY = Math.max(q.lng, maxY)
  }
  if (p.lat < minX || p.lat > maxX || p.lng < minY || p.lng > maxY) {
    return false
  }
  let inside = false
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    if (
      // eslint-disable-next-line no-mixed-operators
      polygon[i].lng > p.lng !== polygon[j].lng > p.lng &&
      p.lat <
        ((polygon[j].lat - polygon[i].lat) * (p.lng - polygon[i].lng)) / (polygon[j].lng - polygon[i].lng) +
          polygon[i].lat
    ) {
      inside = !inside
    }
  }
  return inside
}

const AppUtils = {
  timeToReadable,
  percentToReadable,
  interpolate,
  pointIsInPolygon,
  getIndexByDay,
}

export default AppUtils
