const Draw = {
  draw: {
    toolbar: {
      actions: {
        title: "İptal",
        text: "Şekil seçimini iptal et",
      },
      finish: {
        title: "Bitir",
        text: "Bitir",
      },
      undo: {
        title: "Geri al",
        text: "Son seçimi geri al",
      },
      buttons: {
        polyline: "Polyline",
        polygon: "Poligon Çizin",
        rectangle: "rectangle",
        circle: "circle",
        marker: "marker",
        circlemarker: "circlemarker",
      },
    },
    handlers: {
      circle: {
        tooltip: {
          start: "- your text-",
        },
        radius: "- your text-",
      },
      circlemarker: {
        tooltip: {
          start: "- your text-.",
        },
      },
      marker: {
        tooltip: {
          start: "- your text-.",
        },
      },
      polygon: {
        tooltip: {
          start: "Başlamak için bir nokta seçin",
          cont: "Devam etmek için bir nokta seçin",
          end: "Başlangıç noktasına tıklayarak çizimi bitirin",
        },
      },
      polyline: {
        error: "<strong>Error:</strong> shape edges cannot cross!",
        tooltip: {
          start: "Click to start drawing line.",
          cont: "Click to continue drawing line.",
          end: "Click last point to finish line.",
        },
      },
      rectangle: {
        tooltip: {
          start: "- your text-.",
        },
      },
      simpleshape: {
        tooltip: {
          end: "Release mouse to finish drawing.",
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: "Save changes",
          text: "Save",
        },
        cancel: {
          title: "Cancel editing, discards all changes",
          text: "Cancel",
        },
        clearAll: {
          title: "Clear all layers",
          text: "Clear All",
        },
      },
      buttons: {
        edit: "Edit layers",
        editDisabled: "No layers to edit",
        remove: "Delete layers",
        removeDisabled: "No layers to delete",
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: "Drag handles or markers to edit features.",
          subtext: "Click cancel to undo changes.",
        },
      },
      remove: {
        tooltip: {
          text: "Click on a feature to remove.",
        },
      },
    },
  },
}

const LeafletConfig = {
  Draw,
}

export default LeafletConfig
