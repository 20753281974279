import AppConfig from "../config"

const isAuthenticated = async () => {
  try {
    await AppConfig.axiosInstances.MainAuth.get("/active")
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

const changeCustomersOrderInAreaDay = async ({ areaId, sequenceToApply, day }) => {
  try {
    await AppConfig.axiosInstances.MainAuth.post(`/areas/${areaId}/reorder`, {
      sequenceToApply,
      day,
    })
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

const optimizeCustomersOrderInAreaDay = async ({ areaId, day }) => {
  try {
    await AppConfig.axiosInstances.MainAuth.post(`/areas/${areaId}/optimize/day`, {
      day,
    })
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

const changeCustomersOrderInAreaDays = async ({ areaId, items }) => {
  try {
    await AppConfig.axiosInstances.MainAuth.post(`/areas/${areaId}/withirouteswap`, [...items])
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

const calculateCustomerOrderChangeInAreaDay = async ({ areaId, sequenceToApply, day }) => {
  try {
    const res = await AppConfig.axiosInstances.MainAuth.post(`/areas/${areaId}/tests/reorder`, {
      sequenceToApply,
      day,
    })
    return res.data
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

const calculateCustomerOrderChangeInAreaDays = async ({ areaId, items }) => {
  try {
    const res = await AppConfig.axiosInstances.MainAuth.post(`/areas/${areaId}/tests/withirouteswap`, [...items])
    return res.data
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

const AppServices = {
  isAuthenticated,
  optimizeCustomersOrderInAreaDay,
  changeCustomersOrderInAreaDay,
  changeCustomersOrderInAreaDays,
  calculateCustomerOrderChangeInAreaDay,
  calculateCustomerOrderChangeInAreaDays,
}

export default AppServices
