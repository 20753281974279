import { useCallback, useEffect, useState } from "react"
import L from "leaflet"
// eslint-disable-next-line no-unused-vars
import D from "leaflet-draw"
import { toast } from "react-toastify"
import Button from "react-bootstrap/Button"
import AppConfig from "../../config"
import axios from "axios"
import AppUtils from "../../utils"
import { useDispatch } from "react-redux"
import _ from "lodash"
import useNetworkRequests from "./useNetworkRequests"

const useDashboard = () => {
  const [map, setMap] = useState(undefined)
  const [mapLoading, setMapLoading] = useState(false)
  const [lockLoadingFunction, setLockLoadingFunction] = useState(false)
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [showCustomersModal, setShowCustomersModal] = useState(false)
  const [memoryAreas, setMemoryAreas] = useState({})
  const [operationalCostsLoading, setOperationalCostsLoading] = useState(false)
  const [isOperationalCostModalOpen, setIsOperationalCostModalOpen] = useState(false)
  const [isSwapCustomersBetweenDaysModalOpen, setIsSwapCustomersBetweenDaysModalOpen] = useState(false)

  const { resetBack } = useNetworkRequests()

  const [shiftCustomersBetweenAreasModal, setShiftCustomersBetweenAreasModal] = useState({
    show: false,
    areaIds: [],
    fixFeasibility: false,
    speed: 0,
    lookCorners: false,
    checkFeasibility: false,
    checkUtilization: false,
    checkCorners: false,
    checkDistance: false,
  })
  const [changeAreaModal, setChangeAreaModal] = useState({
    customer: "",
    area: "",
    show: false,
  })
  const [, setSocketData] = useState(undefined)
  const [territoryToChange, setTerritoryToChange] = useState(undefined)
  const [excelUrlToDownload, setExcelUrlToDownload] = useState(undefined)
  const [lockScreen, setLockScreen] = useState(false)
  const [utilizations, setUtilizations] = useState([])
  // null to indicate initial load! to keep ordered utilization, it stores indices to which areas info to show.
  const [displayUtilizations, setDisplayUtilizations] = useState(null)
  const [costChanges, setCostChanges] = useState({
    delta_distance: 0,
    delta_utilization: 0,
  })
  const [projectFileLoadingProgress, setProjectFileLoadingProgress] = useState({
    progress: 0,
    message: "",
    isLoading: false,
  })
  const [posSearch, setPosSearch] = useState("")
  const [customersInsidePool, setCustomersInsidePool] = useState({})
  const [operatinoalCosts, setOperationalCosts] = useState({})
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  })

  const dispatch = useDispatch()

  const getOperatingCosts = async () => {
    try {
      setOperationalCostsLoading(true)
      const response = await AppConfig.axiosInstances.MainAuth.get(`/operationalcosts`)
      setOperationalCosts(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setOperationalCostsLoading(false)
    }
  }

  useEffect(() => {
    getOperatingCosts()
  }, [])

  const downloadFile = async (fileurl) => {
    try {
      const response = await axios({
        method: "GET",
        url: fileurl,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "Rapor.xlsx")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }

  const downloadSampleFile = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_MAIN_BE_URL}${"/api/projectfile/sample"}`,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "SimulasyonForm.xlsx")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }

  const forceChangeArea = async (id, args) => {
    try {
      toast.update(id, {
        render: () => <div>Müşteri taşınıyor...</div>,
        isLoading: true,
      })
      await AppConfig.axiosInstances.MainAuth.patch(`/customers/move`, {
        ...args,
      })
      setChangeAreaModal({ customer: "", area: "", show: false })
    } catch (error) {
      if (error.response?.status === 503) {
        toast.update(id, {
          render: () => <div>Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin.</div>,
          isLoading: false,
          type: "error",
        })
      } else {
        toast.update(id, {
          render: () => <div>Müşteri taşınmada hata oluştu...</div>,
          isLoading: false,
          type: "error",
        })
      }
    }
  }

  function getToastStatus(status) {
    switch (status) {
      case 1:
        return toast.TYPE.INFO
      case 2:
        return toast.TYPE.SUCCESS
      default:
        return toast.TYPE.DEFAULT
    }
  }

  const Msg = ({ item, closeToast }) => {
    return (
      <div
        key={JSON.stringify(item)}
        style={{
          color: "black",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <div> {item.message}</div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="mr-3">
            <Button variant="secondary" size="sm" onClick={closeToast}>
              Vazgeç
            </Button>
          </div>
          <div>
            <Button variant="primary" size="sm" onClick={() => forceChangeArea(item.id, item.args)}>
              Değiştir
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const MsgCanelable = ({ item, closeToast }) => {
    return (
      <div
        key={JSON.stringify(item)}
        style={{
          color: "black",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <div> {item.message}</div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            <Button variant="primary" size="sm" onClick={() => resetBack()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const DownloadFileButton = ({ item, closeToast }) => {
    return (
      <div
        key={JSON.stringify(item)}
        style={{
          color: "white",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <div>{item.title}</div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="mr-3">
            <Button variant="secondary" size="sm" onClick={closeToast}>
              Vazgeç
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                downloadFile(`${process.env.REACT_APP_MAIN_BE_URL}${item.args}`)
              }}
            >
              Indir
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const initUtilizations = useCallback(async () => {
    if (displayUtilizations !== null) return
    try {
      const utilsRes = await AppConfig.axiosInstances.MainAuth.get(`/areas/utilization`)
      const utils = utilsRes.data.map((item) => {
        return {
          ...item,
          active: false,
          activeTime: null, //we will show only 3 most recent selected utils. So date might help without auditing extra references. The aim to fix the triggering all checkboxes, as util array is not limited to 3 items only (we can have more than 3 areas). Here we refer to selecting items based on their index (index at -1 for example, it can be not active);
        }
      })
      //if initial load display first 3 utils;
      let toDisplay = displayUtilizations
      if (toDisplay === null) {
        toDisplay = Array.from({ length: Math.min(3, utils.length) }, (_, i) => i)
      }
      //update utlils active state based on saved/or newly created state! Should help in when data is reloaded.
      toDisplay.forEach((value) => {
        utils[value].active = true
        utils[value].activeTime = new Date()
      })
      setUtilizations(utils)
      setDisplayUtilizations(toDisplay)
    } catch (e) {
      console.error(e)
      toast.error("Alan detayları getirmede hata oluştu")
    }
  }, [displayUtilizations])

  useEffect(() => {
    initUtilizations()
  }, [initUtilizations])

  const drawMap = useCallback(() => {
    if (map) return
    if (!location.lat || !location.lng) return

    const mapboxTiles = L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: '<a href="http://www.mapbox.com/about/maps/" target="_blank">Terms &amp; Feedback</a>',
    })
    const mapObject = L.map("map").addLayer(mapboxTiles).setView([location.lat, location.lng], 9)
    const drawnItems = new L.FeatureGroup()
    mapObject.addLayer(drawnItems)
    const options = {
      draw: {
        polyline: false,
        marker: false,
        circlemarker: false,
        rectangle: false,
        circle: false,
        polygon: {
          allowIntersection: false,
          drawError: {
            color: "#e1e100",
            message: "<strong>Polygon çizgileri üstüste gelemez</strong>",
          },
          shapeOptions: {
            color: "#FF5656",
          },
        },
      },
      edit: {
        featureGroup: drawnItems,
      },
    }
    L.drawLocal = AppConfig.Leaflet.Draw
    const drawControl = new L.Control.Draw(options)
    mapObject.addControl(drawControl)
    mapObject.on(L.Draw.Event.CREATED, function (e) {
      let layer = e.layer
      let polygon = layer.getLatLngs()[0]
      let markers = []
      mapObject.eachLayer((layer) => {
        if (layer.getLatLng && layer.name && AppUtils.pointIsInPolygon(layer.getLatLng(), polygon)) {
          markers.push(layer.id)
        }
      })
      setChangeAreaModal({
        customer: markers,
        area: "",
        show: true,
      })
      const customers = markers.map((marker) => {
        return {
          name: marker.name,
          id: marker.id,
          lat: marker.lat,
          lng: marker.lng,
          area: marker.area,
          internalId: marker.internalId,
        }
      })
      setSelectedCustomers(customers)
      setShowCustomersModal(true)
    })
    setMap(mapObject)
  }, [map, location])

  console.log(location.lat, location.lng)

  const [areas, setAreas] = useState({})

  const getAreas = useCallback(async () => {
    try {
      const areasRes = await AppConfig.axiosInstances.MainAuth.get(`/customers?area_based=1`)
      const areas = areasRes.data

      for (const area in areas) {
        if (areas[area] && areas[area].area?.depo?.lat && areas[area].area?.depo?.lng) {
          setLocation({
            lat: areas[area].area?.depo?.lat,
            lng: areas[area].area?.depo?.lng,
          })
          break
        }
      }

      setAreas(areas)
    } catch (e) {
      console.error(e.message)
    }
  }, [])

  useEffect(() => {
    getAreas()
  }, [getAreas])

  const drawAreas = useCallback(async () => {
    if (!map) return
    if (!areas) return

    try {
      let areaIndex = 0
      for (const area in areas) {
        let areaObject = {
          id: areas[area].area.id,
          name: areas[area].area.title,
          customers: areas[area].customers,
          depo: areas[area].area.depo,
          layer: L.featureGroup(),
        }
        for (const customerObj of areaObject.customers) {
          const customer = customerObj.customer
          let visitDays = ""
          if (customerObj.visitDay0) {
            visitDays = visitDays.concat("Pazartesi, ")
          }
          if (customerObj.visitDay1) {
            visitDays = visitDays.concat("Salı, ")
          }
          if (customerObj.visitDay2) {
            visitDays = visitDays.concat("Çarşamba, ")
          }
          if (customerObj.visitDay3) {
            visitDays = visitDays.concat("Perşembe, ")
          }
          if (customerObj.visitDay4) {
            visitDays = visitDays.concat("Cuma, ")
          }
          if (customerObj.visitDay5) {
            visitDays = visitDays.concat("Cumartesi, ")
          }
          if (customerObj.visitDay6) {
            visitDays = visitDays.concat("Pazar, ")
          }
          visitDays = visitDays.slice(0, -2)

          if (customer.lat === undefined) {
            continue
          }
          const marker = L.circleMarker([customer.lat, customer.lng], {
            radius: 8,
            weight: 1,
            fillOpacity: 1,
            color: "black",
            fillColor: AppUtils.interpolate(areaIndex),
          })
          const parent = document.createElement("div")
          const p1 = document.createElement("p")
          const p1Text = document.createTextNode(`BÖLGE: ${areaObject.name}`)
          p1.style.color = AppUtils.interpolate(areaIndex)
          p1.style.fontWeight = "bold"
          p1.appendChild(p1Text)
          parent.appendChild(p1)

          const p2 = document.createElement("p")
          const p2Text = document.createTextNode(`${customer.customer_name}`)
          p2?.classList?.add("marker-customer-name")
          p2.appendChild(p2Text)
          parent.appendChild(p2)

          if (visitDays.length > 0) {
            const daysOfVisit = document.createElement("p")
            const daysOfVisitText = document.createTextNode(`Ziyaret Günleri: ${visitDays}`)
            daysOfVisit?.classList.add("marker-visit-day")
            daysOfVisit?.appendChild(daysOfVisitText)
            parent.appendChild(daysOfVisit)
          }

          const pos = document.createElement("p")
          const posText = document.createTextNode(`POS ID: ${customer.internal_id}`)
          pos.classList.add("marker-pos-id")
          pos.appendChild(posText)
          parent.appendChild(pos)

          const areaChangeBtn = document.createElement("button")
          const areaChangeBtnText = document.createTextNode("Bölge Değiştir")
          areaChangeBtn?.classList?.add("marker-change-area-button")
          areaChangeBtn.appendChild(areaChangeBtnText)
          areaChangeBtn.onclick = function () {
            setChangeAreaModal({
              customer: customer.id,
              area: areas[area].area,
              show: true,
            })
          }
          parent.appendChild(areaChangeBtn)

          const selectForPoolBtn = document.createElement("button")
          const selectForPoolBtnText = document.createTextNode("Havuza Koy")
          selectForPoolBtn?.classList?.add("marker-pool-button")
          selectForPoolBtn.appendChild(selectForPoolBtnText)
          selectForPoolBtn.onclick = function () {
            setCustomersInsidePool((prevState) => {
              return {
                ...prevState,
                [customer.id]: {
                  id: customer.id,
                  name: customer.customer_name,
                  lat: customer.lat,
                  lng: customer.lng,
                  internalId: customer.internal_id,
                  area: areaObject.name,
                },
              }
            })
          }
          parent.appendChild(selectForPoolBtn)

          marker.area = areaObject.name
          marker.name = customer.customer_name
          marker.id = customer.id
          marker.lat = customer.lat
          marker.lng = customer.lng
          marker.internalId = customer.internal_id
          marker.bindPopup(parent, {
            closeButton: true,
          })
          marker.addTo(areaObject.layer)
        }
        areaIndex++
        map.addLayer(areaObject.layer)
        setMemoryAreas((prevState) => ({
          ...prevState,
          [area]: {
            id: areaObject.id,
            name: areaObject.name,
            customers: areaObject.customers,
            layer: areaObject.layer,
            color: AppUtils.interpolate(parseInt(area)),
            depo: areaObject.depo,
            checked: true,
          },
        }))
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      setMapLoading(false)
    }
  }, [map, areas])

  const reload = useCallback(async () => {
    setLockLoadingFunction(true)
    Object.keys(memoryAreas).forEach((key) => {
      const area = memoryAreas[key]
      const layer = area.layer
      map.removeLayer(layer)
    })
    await new Promise((resolve) => setTimeout(resolve, 4000))
    setMemoryAreas({})
    await drawAreas()
    const utilsRes = await AppConfig.axiosInstances.MainAuth.get(`/areas/utilization`)
    setUtilizations((prevState) => {
      const oldArray = [...prevState]
      const newArray = []
      for (let j = 0; j < utilsRes.data.length; j++) {
        let found = false
        for (let i = 0; i < oldArray.length; i++) {
          if (oldArray[i].area.id === utilsRes.data[j].area.id) {
            newArray.push({
              ...utilsRes.data[j],
              active: oldArray[i].active,
              activeTime: oldArray[i].activeTime,
            })
            found = true
            break
          }
        }
        if (found === false) {
          newArray.push({
            ...utilsRes.data[j],
            active: false,
            activeTime: null,
          })
        }
      }
      return newArray
    })
    setLockLoadingFunction(false)
    setMapLoading(false)
  }, [drawAreas, map, memoryAreas])

  useEffect(() => {
    //need additional lock mechanism to prevent additional loading on state change.
    if (mapLoading === true && lockLoadingFunction === false) reload()
    // for example, we call drawAreas, ones it is called, mapLoding is still true,
    // so we call reaload function again. This implementation locks additional data load.
  }, [map, lockLoadingFunction, mapLoading, reload])

  useEffect(() => {
    if (!map) return
    map.eachLayer((layer) => {
      if (layer.internalId === posSearch && layer.lat && layer.lng) {
        layer.openPopup()
        map.flyTo([layer._latlng?.lat, layer._latlng?.lng])
      } else {
        layer.closePopup()
      }
    })
  }, [map, posSearch])

  const redrawAreas = () => {
    setMapLoading(false)
  }

  const onCloseAreaModal = () => {
    setChangeAreaModal({ customer: "", area: "", show: false })
  }

  const handleFileUploadMessages = async (data) => {
    setProjectFileLoadingProgress({
      progress: Math.floor(parseFloat(data.args.progress) * 100),
      message: data.args.message,
      isLoading: Math.floor(parseFloat(data.args.progress) * 100) < 100,
    })
    if (toast.isActive(data.id)) {
      toast.update(data.id, {
        render: data.args.message,
        type: getToastStatus(data.status),
        autoClose: data.bind ? false : 10000,
        closeOnClick: Boolean(!data.bind),
        isLoading: false,
      })
    } else {
      toast(data.args.message, {
        toastId: data.id,
        type: getToastStatus(data.status),
        autoClose: data.bind ? false : 10000,
        closeOnClick: Boolean(!data.bind),
        isLoading: false,
      })
    }
  }

  const listenSocketMessages = () => {
    const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/notification/default/`)
    socket.onopen = () => {
      socket.send(
        JSON.stringify({ auth: `${sessionStorage.getItem("token")}` }) // Socket authentication
      )
    }
    socket.onclose = (event) => {
      console.warn("Socket is closed. Reconnect will be attempted every second. Reason: ", event.reason)
      toast.warning("Sunucuyla bağlantı hatası oluştu. Lütfen sayfayı yenileyin.")
    }
    socket.onerror = function (err) {
      toast.warning("Sunucuyla bağlantı hatası oluştu. Lütfen sayfayı yenileyin.")
      socket.close()
    }

    socket.onmessage = (event) => {
      const unparsedData = event.data
      if (!unparsedData) {
        console.error("Soket verisi bozuk.")
        setSocketData(undefined)
        return
      }
      const data = JSON.parse(JSON.parse(unparsedData))
      if ("message" in data) {
        console.log(data)
        toast(
          data.requireAction ? <Msg item={data} /> : data.cancelable ? <MsgCanelable item={data} /> : data.message,
          {
            toastId: data.id,
            type: getToastStatus(data.status),
            autoClose: data.bind ? false : 10000,
            closeOnClick: Boolean(!data.bind),
            isLoading: false,
          }
        )
        setSocketData((prevState) => {
          const clonedData = { ...prevState }
          const lastValue = Object.values(clonedData).slice(-1)[0]
          if (!lastValue) {
            toast(data.requireAction ? <Msg item={data} /> : data.message, {
              toastId: data.id,
              type: getToastStatus(data.status),
              autoClose: data.bind ? false : 10000,
              closeOnClick: Boolean(!data.bind),
              isLoading: false,
            })
          } else if (lastValue && prevState[data.id]) {
            toast.update(data.id, {
              render: data.requireAction ? (
                <Msg item={data} />
              ) : data.cancelable ? (
                <MsgCanelable item={data} />
              ) : (
                data.message
              ),
              type: getToastStatus(data.status),
              autoClose: data.bind ? false : 10000,
              closeOnClick: Boolean(!data.bind),
              isLoading: false,
            })
          } else if (lastValue && !prevState[data.id]) {
            toast(
              data.requireAction ? <Msg item={data} /> : data.cancelable ? <MsgCanelable item={data} /> : data.message,
              {
                toastId: data.id,
                type: getToastStatus(data.status),
                autoClose: data.bind ? false : 10000,
                closeOnClick: Boolean(!data.bind),
              }
            )
          }
          return {
            ...prevState,
            [data.id]: {
              ...data,
              id: data.id,
              message: data.message,
            },
          }
        })
      } else if ("task" in data) {
        if (data.task === "reloadallroutes") {
          redrawAreas()
          getOperatingCosts()
          dispatch({
            type: "SET_RELOAD_ALL",
            payload: true,
          })
          setLockScreen(false)
        } else if (data.task === "reloadroutes") {
          getOperatingCosts()
          redrawAreas(data.args)
          setLockScreen(false)
        } else if (data.task === "lockscreen") {
          if (data.args === true) {
            // TODO: maybe readd this: https://rotamat.atlassian.net/browse/ADJ-4
            // setLockScreen(true);
          } else {
            setLockScreen(false)
          }
        } else if (data.task === "costchange") {
          setCostChanges({ ...data.args })
        } else if (data.task === "download") {
          toast.update(data.id, {
            render: data.requireAction ? <DownloadFileButton item={data} /> : data.message,
            type: getToastStatus(data.status),
            autoClose: data.bind ? false : 10000,
            closeOnClick: Boolean(!data.bind),
            isLoading: false,
          })
        } else if (data.task === "fileupload") {
          handleFileUploadMessages(data)
        }
      }
    }
  }

  const onSetTerritoryToChange = ({ name, id }) => {
    setTerritoryToChange({ name, id })
  }

  const onChangeArea = async (newAreaId, customerId) => {
    try {
      var tochange = []
      if (customerId.constructor === Array) {
        customerId.forEach((element) => {
          tochange.push({
            customer: element,
            area: newAreaId,
            do_change: true,
          })
        })
      } else {
        tochange.push({
          customer: customerId,
          area: newAreaId,
          do_change: true,
        })
      }
      await AppConfig.axiosInstances.MainAuth.patch(`/customers/move`, tochange)
      toast.success("İşlemciye istek atandı, lütfen mesajları izlemeye devam edin.")
      setChangeAreaModal({ customer: "", area: "", show: false })
      setCustomersInsidePool({})
    } catch (e) {
      console.error(e)
      if (e.response?.status === 503 || e.response?.status === 423) {
        toast.error("Sunucuda aktif işlem var, lütfen biraz sonra tekrar deneyin")
      } else {
        toast.error(`Beklenmeyen bir hata oluştu, kod: 2, status: ${e.response?.status}`)
      }
    }
  }

  const onCustomerInsidePoolClick = (customer) => {
    if (!map) return
    map.eachLayer((layer) => {
      if (layer.internalId === customer.internalId && layer.lat && layer.lng) {
        layer.openPopup()
        map.flyTo([layer._latlng?.lat, layer._latlng?.lng], 12)
      } else {
        layer.closePopup()
      }
    })
  }

  const onRemoveCustomerFromPoolClick = async (customer) => {
    setCustomersInsidePool((prevState) => {
      const clonedState = _.cloneDeep(prevState)
      delete clonedState[customer.id]
      return clonedState
    })
  }

  const onPoolSaveClick = async () => {
    const customers = Object.keys(customersInsidePool).map((c) => {
      return {
        name: customersInsidePool[c].name,
        id: customersInsidePool[c].id,
        lat: customersInsidePool[c].lat,
        lng: customersInsidePool[c].lng,
        area: customersInsidePool[c].area,
        internalId: customersInsidePool[c].internalId,
      }
    })
    setSelectedCustomers(customers)
    setChangeAreaModal({
      customer: customers.map((c) => c.id),
      area: "",
      show: true,
    })
    setShowCustomersModal(true)
  }

  const onClickCalculateOperationalCosts = async () => {
    try {
      setOperationalCostsLoading(true)
      const response = await AppConfig.axiosInstances.MainAuth.get(`/operationalcosts`)
      setOperationalCosts(response.data)
      toast.success("Operasyonel maliyet bilgisi güncellendi")
    } catch (error) {
      console.log(error)
    } finally {
      setOperationalCostsLoading(false)
    }
  }

  return {
    map,
    selectedCustomers,
    showCustomersModal,
    drawAreas,
    drawMap,
    areas: memoryAreas,
    changeAreaModal,
    onCloseAreaModal,
    listenSocketMessages,
    setTerritoryToChange: onSetTerritoryToChange,
    territoryToChange,
    onChangeArea,
    mapLoading,
    redrawAreas,
    excelUrlToDownload,
    setExcelUrlToDownload,
    lockScreen,
    utilizations,
    setUtilizations,
    displayUtilizations,
    setDisplayUtilizations,
    costChanges,
    projectFileLoadingProgress,
    setProjectFileLoadingProgress,
    posSearch,
    setPosSearch,
    customersInsidePool,
    onCustomerInsidePoolClick,
    onRemoveCustomerFromPoolClick,
    onPoolSaveClick,
    shiftCustomersBetweenAreasModal,
    setShiftCustomersBetweenAreasModal,
    isOperationalCostModalOpen,
    setIsOperationalCostModalOpen,
    downloadSampleFile,
    operatinoalCosts,
    onClickCalculateOperationalCosts: onClickCalculateOperationalCosts,
    calculateLoading: operationalCostsLoading,
    isSwapCustomersBetweenDaysModalOpen,
    setIsSwapCustomersBetweenDaysModalOpen,
  }
}

export default useDashboard
