import "./index.scss";

import { useCallback, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import L from "leaflet";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";

import Icons from "../../../../../assets/icons";
import AppServices from "../../../../../services";
import _ from "lodash";
import AppUtils from "../../../../../utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AreaDayDragDropModal = ({
    open,
    areaName,
    areaId,
    day,
    customers,
    depo,
    onClose,
    onSuccess,
}) => {
    const [mapState, setMapState] = useState(null);
    const [focusedCustomer, setFocusedCustomer] = useState(null);
    // const [dragDropUsed, setDragDropUsed] = useState(false);
    const [pinLayer, setPinLayer] = useState(null);
    const [, setLastChangedIndex] = useState(null);
    const [reorderedCustomers, setReorderedCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [calculateLoading, setCalculateLoading] = useState(false);
    const [costChanges, setCostChanges] = useState(null);
    const reloadAll = useSelector((state) => state.reloadAll);
    const dispatch = useDispatch();

    const reload = async () => {
        try {
            setCostChanges(null);
            await onSuccess();
            dispatch({ type: "SET_RELOAD_ALL", payload: false });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (reloadAll) {
            reload();
        }
    }, [reloadAll]);

    useEffect(() => {
        setReorderedCustomers(customers);
    }, [customers]);

    useEffect(() => {
        if (!open) {
            setFocusedCustomer(null);
            setReorderedCustomers(customers);
            // setDragDropUsed(false);
            setLastChangedIndex(null);
            setCostChanges(null);
            setMapState(null);
        }
    }, [customers, open]);

    useEffect(() => {
        if (!mapState) return;
        if (pinLayer == null) return;
        mapState.addLayer(pinLayer);
        setMapState(mapState);
    }, [pinLayer]);

    const drawMap = useCallback(async () => {
        if (!mapState || !customers?.length) {
            return;
        }
        if (pinLayer != null) {
            mapState.removeLayer(pinLayer);
        }

        const markerLayer = L.featureGroup();

        const markerOptions = {
            clickable: true,
            draggable: false,
            icon: new L.NumberedDivIcon({ number: "D" }),
        };
        const marker = L.marker([depo.lat, depo.lng], markerOptions);
        markerLayer.addLayer(marker);

        for (let i = 0; i < reorderedCustomers?.length; i++) {
            const markerOptions = {
                clickable: true,
                draggable: false,
                icon: new L.NumberedDivIcon({ number: i + 1 }),
            };
            const marker = L.marker(
                [reorderedCustomers[i].lat, reorderedCustomers[i].lng],
                markerOptions
            );
            markerLayer.addLayer(marker);
        }
        setPinLayer(markerLayer);
    }, [mapState, reorderedCustomers, depo]);

    useEffect(() => {
        if (!reorderedCustomers?.length || mapState) return;

        const lat = reorderedCustomers[0].lat;
        const lng = reorderedCustomers[0].lng;
        L.NumberedDivIcon = L.Icon.extend({
            options: {
                iconUrl: Icons.PurpleMarker,
                number: "",
                shadowUrl: null,
                iconSize: new L.Point(50, 50),
                iconAnchor: new L.Point(13, 41),
                popupAnchor: new L.Point(0, -33),
                className: "leaflet-div-icon",
            },
            createIcon() {
                const div = document.createElement("div");
                const img = this._createImg(this.options.iconUrl);
                const numdiv = document.createElement("div");
                numdiv.setAttribute("class", "number");
                numdiv.innerHTML = this.options.number || "";
                img.setAttribute("class", "location-pin-img");
                div.appendChild(img);
                div.appendChild(numdiv);
                this._setIconStyles(div, "icon");
                return div;
            },
            createShadow() {
                return null;
            },
        });
        const mapboxTiles = L.tileLayer(
            "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
                attribution:
                    '<a href="http://www.mapbox.com/about/maps/" target="_blank">Terms &amp; Feedback</a>',
            }
        );
        const map = L.map("daydragdropmap")
            .addLayer(mapboxTiles)
            .setView([lat, lng], 12);
        setMapState(map);
    }, [mapState, reorderedCustomers]);

    useEffect(() => {
        drawMap();
    }, [drawMap]);

    useEffect(() => {
        if (!focusedCustomer?.lat || !mapState) return;
        mapState.flyTo([focusedCustomer.lat, focusedCustomer.lng], 18);
        mapState.eachLayer((layer) => {
            if (
                layer._latlng?.lat === focusedCustomer.lat &&
                layer._latlng?.lng === focusedCustomer.lng
            ) {
                layer.openPopup();
            }
        });
    }, [focusedCustomer, mapState]);

    const onCustomerClick = (customer) => {
        setFocusedCustomer(customer);
    };

    const handleDrop = async (droppedItem) => {
        if (!droppedItem.destination) return; // if dropped to same location

        const updatedList = _.cloneDeep(reorderedCustomers);
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // setDragDropUsed(!_.isEqual(customers, updatedList));
        if (droppedItem.source.index !== droppedItem.destination.index) {
            setReorderedCustomers(updatedList);
            setLastChangedIndex(droppedItem.destination.index);
        }
    };

    const onClickApplyChanges = async () => {
        try {
            setLoading(true);
            const customerIds = reorderedCustomers
                .filter(
                    (customer) => !customer.name.toLowerCase().includes("depo")
                )
                .map((customer) => customer.id);
            await AppServices.changeCustomersOrderInAreaDay({
                areaId,
                sequenceToApply: customerIds,
                day: AppUtils.getIndexByDay[day],
            });
            toast.success("İstek alındı, lütfen bekleyin...");
        } catch (e) {
            toast.error("Bir hata oluştu, kod: 6");
        } finally {
            setLoading(false);
        }
    };

    const onClickOptimize = async () => {
        try {
            setLoading(true);
            const customerIds = reorderedCustomers
                .filter(
                    (customer) => !customer.name.toLowerCase().includes("depo")
                )
                .map((customer) => customer.id);
            await AppServices.optimizeCustomersOrderInAreaDay({
                areaId,
                day: AppUtils.getIndexByDay[day],
            });
            toast.success("Istek atandi!");
            // setCostChanges(null);
            // await new Promise((resolve) => setTimeout(resolve, 2000));
            // onSuccess();
        } catch (e) {
            toast.error("Bir hata oluştu, kod: 6");
        } finally {
            setLoading(false);
        }
    };

    const onClickCalculateChanges = async () => {
        try {
            setCalculateLoading(true);
            const customerIds = reorderedCustomers
                .filter(
                    (customer) => !customer.name.toLowerCase().includes("depo")
                )
                .map((customer) => customer.id);
            const res = await AppServices.calculateCustomerOrderChangeInAreaDay(
                {
                    areaId,
                    sequenceToApply: customerIds,
                    day: AppUtils.getIndexByDay[day],
                }
            );
            setCostChanges(res);
        } catch (e) {
            toast.error("Bir hata oluştu, kod: 7");
        } finally {
            setCalculateLoading(false);
        }
    };

    const onClickReset = () => {
        setReorderedCustomers(customers);
        // setDragDropUsed(false);
        setLastChangedIndex(null);
        // setCostChanges(null);
        onClickCalculateChanges();
    };

    return (
        <Modal
            show={open}
            onHide={onClose}
            backdrop="static"
            dialogClassName="modal-1280"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {areaName} {day} Günü
                </Modal.Title>
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            Sürükle bırak ile müşterilerin sırasını
                            değiştirebilirsiniz.
                        </Tooltip>
                    }
                >
                    <div>
                        <i
                            className="fa-solid fa-circle-info fa-lg text-black"
                            style={{ marginLeft: 10 }}
                        />
                    </div>
                </OverlayTrigger>
            </Modal.Header>
            <Modal.Body style={{ position: "relative", height: 700 }}>
                <div id="daydragdropmap" />
                <div className="drag-container">
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                            marginTop: 15,
                            marginLeft: 15,
                            marginRight: 15,
                        }}
                    >
                        <Button
                            variant="primary"
                            onClick={onClickApplyChanges}
                            disabled={false} //{!dragDropUsed || loading}
                            size="sm"
                        >
                            Uygula
                            {loading ? (
                                <i
                                    className="fa-solid fa-spinner fa-spin"
                                    style={{ marginLeft: 4 }}
                                />
                            ) : (
                                <i
                                    className="fa-solid fa-arrow-up-right-from-square fa-xs"
                                    style={{ marginLeft: 4 }}
                                ></i>
                            )}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onClickCalculateChanges}
                            disabled={calculateLoading} //{!dragDropUsed || calculateLoading}
                            size="sm"
                        >
                            Hesapla
                            {calculateLoading ? (
                                <i
                                    className="fa-solid fa-spinner fa-spin"
                                    style={{ marginLeft: 4 }}
                                />
                            ) : (
                                <i
                                    className="fa-solid fa-calculator fa-xs"
                                    style={{ marginLeft: 4 }}
                                ></i>
                            )}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onClickReset}
                            disabled={false} //{!dragDropUsed}
                            size="sm"
                        >
                            Sıfırla
                            <i
                                className="fa-solid fa-clock-rotate-left"
                                style={{ marginLeft: 4 }}
                            />
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onClickOptimize}
                            disabled={false} //{!dragDropUsed || loading}
                            size="sm"
                        >
                            {"Optimize et "}
                            {loading ? (
                                <i
                                    className="fa-solid fa-spinner fa-spin"
                                    style={{ marginLeft: 4 }}
                                />
                            ) : (
                                <i
                                    className="fa-solid fa-arrow-up-right-from-square fa-xs"
                                    style={{ marginLeft: 4 }}
                                ></i>
                            )}
                        </Button>
                    </div>
                    <div
                        className="d-flex justify-content-end align-items-center flex-row"
                        style={{
                            margin: 15,
                        }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-between w-100"
                            style={{ color: "white", fontSize: 12 }}
                        >
                            {costChanges?.distance &&
                            Number(costChanges?.distance?.toFixed(2)) !== 0 ? (
                                <div className="d-flex align-items-center">
                                    Mesafe: {costChanges?.distance?.toFixed(2)}
                                    {"km "}
                                    <div style={{ marginLeft: 5 }}>
                                        {Number(
                                            costChanges?.delta_distance?.toFixed(
                                                2
                                            )
                                        ) > 0 ? (
                                            <div className="costchange-positive">
                                                +{" "}
                                                {costChanges.delta_distance.toFixed(
                                                    2
                                                )}{" "}
                                                km
                                            </div>
                                        ) : null}
                                        {Number(
                                            costChanges?.delta_distance?.toFixed(
                                                2
                                            )
                                        ) < 0 ? (
                                            <div className="costchange-negative">
                                                {costChanges.delta_distance.toFixed(
                                                    2
                                                )}{" "}
                                                km
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                            {costChanges?.delta_util &&
                            Number(costChanges?.delta_util?.toFixed(2)) !==
                                0 ? (
                                <div className="d-flex align-items-center">
                                    Verimlilik:
                                    <div style={{ marginLeft: 5 }}>
                                        {Number(
                                            costChanges?.delta_util?.toFixed(2)
                                        ) > 0 ? (
                                            <div className="costchange-positive">
                                                %{" "}
                                                {costChanges?.delta_util.toFixed(
                                                    2
                                                )}
                                            </div>
                                        ) : null}
                                        {Number(
                                            costChanges?.delta_util?.toFixed(2)
                                        ) < 0 ? (
                                            <div className="costchange-negative">
                                                %
                                                {costChanges?.delta_util.toFixed(
                                                    2
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable
                            droppableId="list-container"
                            isDropDisabled={false}
                        >
                            {(provided) => (
                                <div
                                    className="w-100"
                                    ref={provided.innerRef}
                                    style={{
                                        overflow: "hidden",
                                        overflowY: "auto",
                                        height: "86%",
                                    }}
                                >
                                    {reorderedCustomers.map((c, i) => {
                                        return (
                                            <Draggable
                                                key={`draggable${c.name}${i}`}
                                                draggableId={`draggable${c.name}${i}`}
                                                index={i}
                                            >
                                                {(providedd) => (
                                                    <div
                                                        {...providedd.dragHandleProps}
                                                        {...providedd.draggableProps}
                                                        ref={providedd.innerRef}
                                                    >
                                                        <div
                                                            className="drag-item-container"
                                                            key={JSON.stringify(
                                                                c
                                                            )}
                                                            onClick={() =>
                                                                onCustomerClick(
                                                                    c
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className="drag-item"
                                                                style={{
                                                                    width: "90%",
                                                                    margin: "0 auto",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginRight: 5,
                                                                    }}
                                                                >
                                                                    {i + 1} -{" "}
                                                                    {c.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AreaDayDragDropModal;
